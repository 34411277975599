<template>
  <section>
    <span class="f15 fw-bold">{{$t('Requests')}}</span>

    <div v-if="items && items.length > 0">
      <v-card class="my-2" v-for="(item, index) in items" :key="index" flat hover ripple color="white" @click="handleClickItem(item)">
        <b-list-group-item class="d-flex align-items-center rounded-lg">

          <v-avatar v-if="item.status == 'UNCHECKED'" color="red lighten-5 p-2" size="48">
            <!-- infosquare -->
            <iconly type="broken" name="infosquare" class="red--text"/>
          </v-avatar>
          <v-avatar v-if="item.status == 'ONPROGRESS'" color="primary lighten-5 p-2" size="48">
            <iconly type="outline" name="timesquare" class="primary--text"/>
          </v-avatar>
          <v-avatar v-if="item.status == 'DONE'" color="green lighten-5 p-2" size="48">
            <iconly type="outline" name="ticksquare" class="green--text"/>
          </v-avatar>


          <div class="d-flex flex-column ms-4 me-0 align-items-start justify-content-center">
            <div class="d-flex flex-row">
              <span class="f14 red--text" v-if="item.status == 'UNCHECKED'">{{ $t('Pending') }}</span>

              <span class="f14 primary--text" v-if="item.status == 'ONPROGRESS'">{{ $t('In progress') }}</span>

              <span class="f14 green--text" v-if="item.status == 'DONE'">{{ $t('Done') }}</span>

            </div>
            
            <span class="f12 grey--text d-flex flex-row align-items-center">
            <i class="far fa-calendar-alt ms-0 me-1"/>
            {{ item.createDate | moment("dddd , jD jMMMM jYYYY") }} 
            <i class="far fa-clock ms-2 me-1"/>
            {{ item.createDate | moment("HH:MM") }} 
            </span>
          </div>


          <div class="d-flex ms-auto me-0 align-items-end justify-content-center">
            <span class="f14 red--text">{{ item.totalCash | VMask(currencyMask) }} {{ $t('RIAL') }}</span>
          </div>
        </b-list-group-item>
      </v-card>
    </div>
    <span class="text-center f16 d-block mt-8" v-else>
      {{$t('No results found')}}
    </span>

    <infinite-loading @infinite="infiniteHandler">
      <div slot="spinner">
        <v-icon>mdi-selection-ellipse spin</v-icon>
      </div>
      <div slot="no-more">
        <v-icon>mdi-access-point-off</v-icon>
      </div>
      <div slot="no-results">
        <v-icon>mdi-access-point-off</v-icon>
      </div>
    </infinite-loading>
  </section>
</template>

<script>
import apiService from "@/http/ApiService";
import moment from "moment-jalaali";
import fa from "moment/locale/fa";
moment.locale("fa", fa);
moment.loadPersian();

import createNumberMask from 'text-mask-addons/dist/createNumberMask';

  const currencyMask = createNumberMask({
    prefix: '',
    allowDecimal: false,
    includeThousandsSeparator: true,
    allowNegative: false,
  });

export default {
  data() {
    return {
      currencyMask,

      items: [],
      page: 1,
      size: 12,
    }
  },
  methods: {
    getSettlements(page, size) {
      apiService.getSettlements(page, size, "createDate,desc")
        .then((response) => {
          this.items = response.data.content;
        })
        .catch(() => {
          this.$router.go(-1);
        });
    },
    infiniteHandler($state) {
      apiService.getSettlements(this.page, this.size, "createDate,desc")
        .then((response) => {
          if (response.data.content.length) {
            response.data.content.forEach(element => {
              this.items.push(element);
            });

            this.page += 1;
            $state.loaded();
          }
          else {
            $state.complete();
          }
        })
        .catch(() => {
          $state.error();
        });
    },
    handleClickItem(item) {
      this.$router.push({name: 'DoctorSettlementDetails', params: {obj: {...item}, settlementId: item.id}})
    },
  },
  beforeMount() {
    this.$store.commit('update_backActive', true);
    this.$store.commit('update_helloActive', false);
    this.$store.commit('update_threeDotsActive', false);
    this.$store.commit('update_notificationActive', true);
    this.$store.commit('update_toolbarTitleActive', true);

    
    this.getSettlements(0, this.size);
  },
}
</script>
