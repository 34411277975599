var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "section",
    [
      _c("span", { staticClass: "f15 fw-bold" }, [
        _vm._v(_vm._s(_vm.$t("Requests"))),
      ]),
      _vm.items && _vm.items.length > 0
        ? _c(
            "div",
            _vm._l(_vm.items, function (item, index) {
              return _c(
                "v-card",
                {
                  key: index,
                  staticClass: "my-2",
                  attrs: { flat: "", hover: "", ripple: "", color: "white" },
                  on: {
                    click: function ($event) {
                      return _vm.handleClickItem(item)
                    },
                  },
                },
                [
                  _c(
                    "b-list-group-item",
                    { staticClass: "d-flex align-items-center rounded-lg" },
                    [
                      item.status == "UNCHECKED"
                        ? _c(
                            "v-avatar",
                            {
                              attrs: { color: "red lighten-5 p-2", size: "48" },
                            },
                            [
                              _c("iconly", {
                                staticClass: "red--text",
                                attrs: { type: "broken", name: "infosquare" },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                      item.status == "ONPROGRESS"
                        ? _c(
                            "v-avatar",
                            {
                              attrs: {
                                color: "primary lighten-5 p-2",
                                size: "48",
                              },
                            },
                            [
                              _c("iconly", {
                                staticClass: "primary--text",
                                attrs: { type: "outline", name: "timesquare" },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                      item.status == "DONE"
                        ? _c(
                            "v-avatar",
                            {
                              attrs: {
                                color: "green lighten-5 p-2",
                                size: "48",
                              },
                            },
                            [
                              _c("iconly", {
                                staticClass: "green--text",
                                attrs: { type: "outline", name: "ticksquare" },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                      _c(
                        "div",
                        {
                          staticClass:
                            "d-flex flex-column ms-4 me-0 align-items-start justify-content-center",
                        },
                        [
                          _c("div", { staticClass: "d-flex flex-row" }, [
                            item.status == "UNCHECKED"
                              ? _c("span", { staticClass: "f14 red--text" }, [
                                  _vm._v(_vm._s(_vm.$t("Pending"))),
                                ])
                              : _vm._e(),
                            item.status == "ONPROGRESS"
                              ? _c(
                                  "span",
                                  { staticClass: "f14 primary--text" },
                                  [_vm._v(_vm._s(_vm.$t("In progress")))]
                                )
                              : _vm._e(),
                            item.status == "DONE"
                              ? _c("span", { staticClass: "f14 green--text" }, [
                                  _vm._v(_vm._s(_vm.$t("Done"))),
                                ])
                              : _vm._e(),
                          ]),
                          _c(
                            "span",
                            {
                              staticClass:
                                "f12 grey--text d-flex flex-row align-items-center",
                            },
                            [
                              _c("i", {
                                staticClass: "far fa-calendar-alt ms-0 me-1",
                              }),
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm._f("moment")(
                                      item.createDate,
                                      "dddd , jD jMMMM jYYYY"
                                    )
                                  ) +
                                  " "
                              ),
                              _c("i", {
                                staticClass: "far fa-clock ms-2 me-1",
                              }),
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm._f("moment")(item.createDate, "HH:MM")
                                  ) +
                                  " "
                              ),
                            ]
                          ),
                        ]
                      ),
                      _c(
                        "div",
                        {
                          staticClass:
                            "d-flex ms-auto me-0 align-items-end justify-content-center",
                        },
                        [
                          _c("span", { staticClass: "f14 red--text" }, [
                            _vm._v(
                              _vm._s(
                                _vm._f("VMask")(
                                  item.totalCash,
                                  _vm.currencyMask
                                )
                              ) +
                                " " +
                                _vm._s(_vm.$t("RIAL"))
                            ),
                          ]),
                        ]
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            }),
            1
          )
        : _c("span", { staticClass: "text-center f16 d-block mt-8" }, [
            _vm._v(" " + _vm._s(_vm.$t("No results found")) + " "),
          ]),
      _c("infinite-loading", { on: { infinite: _vm.infiniteHandler } }, [
        _c(
          "div",
          { attrs: { slot: "spinner" }, slot: "spinner" },
          [_c("v-icon", [_vm._v("mdi-selection-ellipse spin")])],
          1
        ),
        _c(
          "div",
          { attrs: { slot: "no-more" }, slot: "no-more" },
          [_c("v-icon", [_vm._v("mdi-access-point-off")])],
          1
        ),
        _c(
          "div",
          { attrs: { slot: "no-results" }, slot: "no-results" },
          [_c("v-icon", [_vm._v("mdi-access-point-off")])],
          1
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }